import * as React from "react"
import { graphql } from "gatsby"
import { Layout, PrismicSlice } from '../components'

class Page extends React.Component {
  render() {
    const { 
      data: {
        prismicPage: {
          uid,
          data: {
            background: {
              localFile
            },
            title,
            description,
            ogimage,
            body
          }
        }
      }
    } = this.props;
    
    if (body.length > 0) {
      return (
        <Layout 
          background={localFile}
          context={{
            title: title.text,
            description: description.text,
            ogimage: ogimage.localFile.publicURL,
            slug: `/${uid}/`,
          }}
        >
          <div>
            <PrismicSlice body={body} />
          </div>
        </Layout>
      )
    } else {
      return <Layout></Layout>
    }
  }
}

export default Page;
export const pageQuery = graphql`
  query currentPage($slug: String!) {
    prismicPage(uid: { eq: $slug }) {
      uid
      data {
        background {
          localFile {
            publicURL
          }
        }
        title {
          text
        }
        ogimage {
          localFile {
            publicURL
          }
        }
        description {
          text
        }
        body {
          ... on PrismicPageDataBodyDates {
            items {
              dates {
                document {
                  ... on PrismicConcert {
                    data {
                      date(formatString: "DD/MM/Y à H:m")
                      description {
                        text
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPageDataBodyAlbum {
            id
            primary {
              album_item {
                document {
                  ... on PrismicAlbums {
                    uid
                    data {
                      applemusic {
                        url
                      }
                      bandcamp {
                        url
                      }
                      cover {
                        alt
                        localFile {
                          publicURL
                        }
                      }
                      description {
                        html
                      }
                      more_links {
                        label
                        link {
                          url
                        }
                      }
                      spotify {
                        url
                      }
                      title {
                        text
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPageDataBodyAlbums {
            id
            primary {
              albums_collection {
                document {
                  ... on PrismicAlbumscollection {
                    id
                    data {
                      items {
                        item {
                          document {
                            ... on PrismicAlbums {
                              uid
                              data {
                                applemusic {
                                  url
                                }
                                bandcamp {
                                  url
                                }
                                cover {
                                  alt
                                  localFile {
                                    publicURL
                                  }
                                }
                                more_links {
                                  label
                                  link {
                                    url
                                  }
                                }
                                spotify {
                                  url
                                }
                                title {
                                  text
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPageDataBodyTexte {
            id
            primary {
              texte {
                html
              }
            }
          }
          ... on PrismicPageDataBodyVideo {
            id
          }
          ... on PrismicPageDataBodyVideos {
            id
          }
        }
      }
    }
  }
`